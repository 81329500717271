var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataRows,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.channelGateways",fn:function(ref){
var item = ref.item;
return [_c('channel-endpoints',{attrs:{"item":item}})]}},{key:"item.orderDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order.date ? _vm.$d(new Date(item.order.date), "datetime") : "")+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("integrations.order_fulfillment.status." + item.state))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('link-button',{attrs:{"label":_vm.$t('integrations.fields.details'),"icon":"mdi-eye","route":{ name: 'orderFulfillments_show', params: { id: item.id } }}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }